<template>
  <div>
    <div class="workbench_lunbo flex_center">
      <div :class="index==0?'white workbench_lunbo_prev cur_point':'blue workbench_lunbo_prev cur_point'" @click="direction('prev')" :style="{cursor:index==0?'not-allowed':'pointer'}">
        <img :src="index==0?prevImg:nextImg" :class="index==0?'':'deg180'" />
      </div>
      <div class="workbench_lunbo_con">
        <ul class="workbench_link_div flex_center_box" ref="lunbo">
          <li v-for="(item, index) in titData" :key="index" class="cur_point">
            <div @click="go(index + 1)">
              <div class="workbench_tit">{{ item.tit }}</div>
              <div class="workbench_icon"><img :src="item.img" /></div>
              <div class="workbench_tol">{{ item.tol }}</div>
              <div class="workbench_num">{{ item.num }}</div>
            </div>
            <div class="workbench_bottom" @click="onResearch(index + 1)">
              <div class="iconfont icon-xiayiye-xianxing3-0"></div>
            </div>
          </li>
        </ul>
      </div>
      <div :class="index==(lunboIndex-5)?'white workbench_lunbo_next cur_point':'blue workbench_lunbo_next cur_point'" @click="direction('next')" :style="{cursor:index==3?'not-allowed':'pointer'}">
        <img :src="index==(lunboIndex-5)?prevImg:nextImg" :class="index==(lunboIndex-5)?'deg180':''">
      </div>
    </div>

    <!-- 我的发票 -->
    <div class="table_common_work" ref="performance1">
      <div class="table_common_work_tit flex_center_between_box">
        <div>我的发票</div>
        <div class="flex_center_end_box workbench_table_title_more" @click="go(1)">查看更多<div class="iconfont icon-xiayiye-xianxing3-0"></div>
        </div>
      </div>
      <div class="flex_center_between_box table_common_work_echart_div">
        <div class="table_common_work_echart flex_center_between_box">
          <div ref="echarts1" class="table_common_work_echart1" v-show="this.echarts1Flag"></div>
          <div class="nodata" v-show="!this.echarts1Flag">暂无数据，敬请期待…</div>
          <div class="table_common_work_echart_details" v-show="this.echarts1Flag">
            <ul>
              <li class="flex_center_between_box">
                <div class="tit">发票总数：</div>
                <el-popover placement="top" trigger="hover" :content="filter(data.totalInvoiceAmount) + '元'" popper-class="popper_class_account">
                  <div slot="reference" class="text_overflow">{{data.totalInvoiceAmount | NumFormat}}元</div>
                </el-popover>
              </li>
              <li class="flex_center_between_box">
                <div class="tit">已付款总额：</div>
                <el-popover placement="top" trigger="hover" :content="filter(data.totalPaid) + '元'" popper-class="popper_class_account">
                  <div slot="reference" class="text_overflow">{{data.totalPaid | NumFormat}}元</div>
                </el-popover>
              </li>
              <li class="flex_center_between_box">
                <div class="tit">未付款总额：</div>
                <el-popover placement="top" trigger="hover" :content="filter(data.totalUnpaid) + '元'" popper-class="popper_class_account">
                  <div slot="reference" class="text_overflow">{{data.totalUnpaid | NumFormat}}元</div>
                </el-popover>
              </li>
              <li class="flex_center_between_box">
                <div class="tit over1" title="已贷款发票总额：">已贷款发票总额：</div>
                <el-popover placement="top" trigger="hover" :content="filter(data.totalLoanInvoices) + '元'" popper-class="popper_class_account">
                  <div slot="reference" class="text_overflow">{{data.totalLoanInvoices | NumFormat}}元</div>
                </el-popover>
              </li>
              <li class="flex_center_between_box">
                <div class="tit">贷款额比率：</div>
                <div>{{data.loanRatio}}%</div>
              </li>
            </ul>
          </div>
        </div>
        <div class="table_common_work_echart flex_center_between_box">
          <div ref="echarts2" class="table_common_work_echart1" v-show="this.echarts2Flag"></div>
          <div class="nodata" v-show="!this.echarts2Flag">暂无数据，敬请期待…</div>
          <div class="table_common_work_echart_details" v-show="this.echarts2Flag">
            <ul>
              <li class="flex_center_between_box">
                <div class="tit">发票总数量：</div>
                <div>{{data.totalInvoiceQuantity}}张</div>
              </li>
              <li class="flex_center_between_box">
                <div class="tit">已融资数量：</div>
                <div>{{data.quantityFinanced}}张</div>
              </li>
              <li class="flex_center_between_box">
                <div class="tit">未融资数量：</div>
                <div>{{data.unfundedQuantity}}张</div>
              </li>
              <li class="flex_center_between_box">
                <div class="tit">融资数比率：</div>
                <div>{{data.financingRatio}}%</div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div>
        <div class="workbench_table_title_total flex_box">
          <div class="workbench_table_title_total_bottom_dashed">
            <div class="flex_box">
              <div class="workbench_table_title_total_tit">平均账期(天)</div>
              <div class="workbench_table_title_total_button">{{data.avgAccount}}</div>
              <div class="vertical_line"></div>
            </div>
            <div class="flex_box">
              <div class="workbench_table_title_total_tit1">最长账期(天)</div>
              <div class="workbench_table_title_total_button">{{data.maxAccount}}</div>
              <div class="vertical_line"></div>
            </div>
            <div class="flex_box">
              <div class="workbench_table_title_total_tit1">最短账期(天)</div>
              <div class="workbench_table_title_total_button">{{data.minAccount}}</div>
              <div class="vertical_line"></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 我的融资 -->
    <div class="table_common_work" ref="performance2">
      <div class="table_common_work_tit flex_center_between_box">
        <div>我的融资</div>
        <div class="flex_center_end_box workbench_table_title_more" @click="go(2)">查看更多<div class="iconfont icon-xiayiye-xianxing3-0"></div>
        </div>
      </div>
      <div class="flex_center_between_box table_common_work_echart_div">
        <div class="table_common_work_echart table_common_work_echart2 flex_center_between_box">
          <div ref="echarts3" class="table_common_work_echart1" v-show="this.echarts3Flag"></div>
          <div class="nodata" v-show="!this.echarts3Flag">暂无数据，敬请期待…</div>
          <div class="table_common_work_echart_details table_common_work_echart_details2" v-show="this.echarts3Flag">
            <ul>
              <li class="flex_center_between_box">
                <div class="tit">贷款总额：</div>
                <el-popover placement="top" trigger="hover" :content="filter(data.totalLoan) + '元'" popper-class="popper_class_account">
                  <div slot="reference" class="text_overflow">{{data.totalLoan | NumFormat}}元</div>
                </el-popover>
              </li>
              <li class="flex_center_between_box">
                <div class="tit">最大贷款额：</div>
                <el-popover placement="top" trigger="hover" :content="filter(data.maxLoanAmount) + '元'" popper-class="popper_class_account">
                  <div slot="reference" class="text_overflow">{{data.maxLoanAmount | NumFormat}}元</div>
                </el-popover>
              </li>
              <li class="flex_center_between_box">
                <div class="tit">最小贷款额：</div>
                <el-popover placement="top" trigger="hover" :content="filter(data.minLoanAmount) + '元'" popper-class="popper_class_account">
                  <div slot="reference" class="text_overflow">{{data.minLoanAmount | NumFormat}}元</div>
                </el-popover>
              </li>
              <li class="flex_center_between_box">
                <div class="tit">平均贷款额：</div>
                <el-popover placement="top" trigger="hover" :content="filter(data.avgLoanAmount) + '元'" popper-class="popper_class_account">
                  <div slot="reference" class="text_overflow">{{data.avgLoanAmount | NumFormat}}元</div>
                </el-popover>
              </li>
            </ul>
          </div>
        </div>
        <div class="table_common_work_echart table_common_work_echart2 flex_center_between_box">
          <div ref="echarts4" class="table_common_work_echart1" v-show="this.echarts4Flag"></div>
          <div class="nodata" v-show="!this.echarts4Flag">暂无数据，敬请期待…</div>
          <div class="table_common_work_echart_details table_common_work_echart_details2" v-show="this.echarts4Flag">
            <ul>
              <li class="flex_center_between_box">
                <div class="tit">投资方数量：</div>
                <div>{{data.investorNum}}</div>
              </li>
              <li class="flex_center_between_box">
                <div class="tit">发起融资数：</div>
                <div>{{data.initiatedFinancingNum}}</div>
              </li>
              <li class="flex_center_between_box">
                <div class="tit">融资成功数：</div>
                <div>{{data.successfulFinancingNum}}</div>
              </li>
              <li class="flex_center_between_box">
                <div class="tit">融资失败数：</div>
                <div>{{data.failFinancingNum}}</div>
              </li>
              <li class="flex_center_between_box">
                <div class="tit">融资申请中数：</div>
                <div>{{data.financingInProgress}}</div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div>
        <div class="workbench_table_title_total flex_box">
          <div class="workbench_table_title_total_bottom_dashed">
            <div class="flex_box">
              <div class="workbench_table_title_total_tit">投资最高利率(%)</div>
              <div class="workbench_table_title_total_button">{{data.maxInterestRate}}%</div>
              <div class="vertical_line"></div>
            </div>
            <div class="flex_box">
              <div class="workbench_table_title_total_tit1">投资平均利率(%)</div>
              <div class="workbench_table_title_total_button">{{data.avgInterestRate}}%</div>
              <div class="vertical_line"></div>
            </div>
            <div class="flex_box">
              <div class="workbench_table_title_total_tit1">投资最低利率(%)</div>
              <div class="workbench_table_title_total_button">{{data.minInterestRate}}%</div>
              <div class="vertical_line"></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 我的供应商 -->
    <div class="table_common_work" ref="performance3">
      <div class="table_common_work_tit flex_center_between_box">
        <div>我的供应商</div>
        <div class="flex_center_end_box workbench_table_title_more" @click="go(3)">
          查看更多
          <div class="iconfont icon-xiayiye-xianxing3-0"></div>
        </div>
      </div>

      <div class="flex_center_between_box table_common_work_echart_div">
        <!-- 供应商红榜 -->
        <div class="table_common_work_echart table_common_work_echart7">
          <div ref="redEcharts" class="table_common_work_echart1_allwidth echart_sup" v-show="this.echarts5Flag"></div>
          <div class="nodata nodataTop" v-show="!this.echarts5Flag">暂无数据，敬请期待…</div>
          <!-- <div class="no_echart_title">供应商红榜</div>
          <div class="no_echart_tip">暂无数据，敬请期待…</div> -->
        </div>
        <!-- 供应商黑榜 -->
        <div class="table_common_work_echart table_common_work_echart7">
          <div ref="blackEcharts" class="table_common_work_echart1_allwidth echart_sup" v-show="this.echarts6Flag"></div>
          <div class="nodata nodataTop" v-show="!this.echarts6Flag">暂无数据，敬请期待…</div>
          <!-- <div class="no_echart_title">供应商黑榜</div>
          <div class="no_echart_tip">暂无数据，敬请期待…</div> -->
        </div>
      </div>

      <div class="flex_center_between_box table_common_work_echart_div">
        <!-- 交易数量 -->
        <div class="table_common_work_echart table_common_work_echart7">
          <div ref="tranNumEcharts" class="table_common_work_echart1_allwidth" v-show="this.echarts7Flag"></div>
          <div class="nodata nodataTop" v-show="!this.echarts7Flag">暂无数据，敬请期待…</div>
        </div>
        <!-- 交易金额 -->
        <div class="table_common_work_echart table_common_work_echart7">
          <div ref="accountEcharts" class="table_common_work_echart1_allwidth" v-show="this.echarts8Flag"></div>
          <div class="nodata nodataTop" v-show="!this.echarts8Flag">暂无数据，敬请期待…</div>
        </div>
      </div>

      <div class="flex_center_between_box table_common_work_echart_div">
        <!-- 交易金额占比 -->
        <div class="table_common_work_echart table_common_work_echart7 no_mar">
          <div ref="propEcharts" class="table_common_work_echart1_allwidth" v-show="this.echarts9Flag"></div>
          <div class="nodata nodataTop" v-show="!this.echarts9Flag">暂无数据，敬请期待…</div>
        </div>
        <!-- 交易地区统计 -->
        <div class="table_common_work_echart table_common_work_echart7 no_mar">
          <div ref="areaEcharts" class="table_common_work_echart1_allwidth" v-show="this.echarts10Flag"></div>
          <div class="nodata nodataTop" v-show="!this.echarts10Flag">暂无数据，敬请期待…</div>
        </div>
      </div>
    </div>

    <!-- 我的客户 -->
    <div class="table_common_work" ref="performance4">
      <div class="table_common_work_tit flex_center_between_box">
        <div>我的客户</div>
        <div class="flex_center_end_box workbench_table_title_more" @click="go(4)">
          查看更多
          <div class="iconfont icon-xiayiye-xianxing3-0"></div>
        </div>
      </div>

      <div class="flex_center_between_box table_common_work_echart_div">
        <!-- 客户红榜 -->
        <div class="table_common_work_echart table_common_work_echart7">
          <div ref="cusRedEcharts" class="table_common_work_echart1_allwidth echart_sup" v-show="this.echarts11Flag"></div>
          <div class="nodata nodataTop" v-show="!this.echarts11Flag">暂无数据，敬请期待…</div>
          <!-- <div class="no_echart_title">客户红榜</div>
          <div class="no_echart_tip">暂无数据，敬请期待…</div> -->
        </div>
        <!-- 客户黑榜 -->
        <div class="table_common_work_echart table_common_work_echart7">
          <div ref="cusBlackEcharts" class="table_common_work_echart1_allwidth echart_sup" v-show="this.echarts12Flag"></div>
          <div class="nodata nodataTop" v-show="!this.echarts12Flag">暂无数据，敬请期待…</div>
          <!-- <div class="no_echart_title">客户黑榜</div>
          <div class="no_echart_tip">暂无数据，敬请期待…</div> -->
        </div>
      </div>

      <div class="flex_center_between_box table_common_work_echart_div">
        <!-- 交易数量 -->
        <div class="table_common_work_echart table_common_work_echart7">
          <div ref="cusTranNumEcharts" class="table_common_work_echart1_allwidth" v-show="this.echarts13Flag"></div>
          <div class="nodata nodataTop" v-show="!this.echarts13Flag">暂无数据，敬请期待…</div>
        </div>
        <!-- 交易金额 -->
        <div class="table_common_work_echart table_common_work_echart7">
          <div ref="cusAccountEcharts" class="table_common_work_echart1_allwidth" v-show="this.echarts14Flag"></div>
          <div class="nodata nodataTop" v-show="!this.echarts14Flag">暂无数据，敬请期待…</div>
        </div>
      </div>

      <div class="flex_center_between_box table_common_work_echart_div">
        <!-- 交易金额占比 -->
        <div class="table_common_work_echart table_common_work_echart7 no_mar">
          <div ref="cusPropEcharts" class="table_common_work_echart1_allwidth" v-show="this.echarts15Flag"></div>
          <div class="nodata nodataTop" v-show="!this.echarts15Flag">暂无数据，敬请期待…</div>
        </div>
        <!-- 交易地区统计 -->
        <div class="table_common_work_echart table_common_work_echart7 no_mar">
          <div ref="cusAreaEcharts" class="table_common_work_echart1_allwidth" v-show="this.echarts16Flag"></div>
          <div class="nodata nodataTop" v-show="!this.echarts16Flag">暂无数据，敬请期待…</div>
        </div>
      </div>
    </div>

    <!-- 我的投资 -->
    <div class="table_common_work" ref="performance5">
      <div class="table_common_work_tit flex_center_between_box">
        <div>我的投资</div>
        <div class="flex_center_end_box workbench_table_title_more" @click="go(5)">查看更多<div class="iconfont icon-xiayiye-xianxing3-0"></div>
        </div>
      </div>
      <div class="flex_center_between_box table_common_work_echart_div">
        <div class="table_common_work_echart flex_center_between_box echart_between">
          <div ref="echarts10" class="table_common_work_echart1" v-show="this.echarts17Flag"></div>
          <div class="nodata" v-show="!this.echarts17Flag">暂无数据，敬请期待…</div>
          <div class="table_common_work_echart_details" v-show="this.echarts17Flag">
            <ul>
              <li class="flex_center_between_box">
                <div class="tit">投资单数：</div>
                <div>{{dataListTotal.investmentOrders}}</div>
              </li>
              <li class="flex_center_between_box">
                <div class="tit">已还款单数：</div>
                <div>{{dataListTotal.repaymentOrders}}</div>
              </li>
              <li class="flex_center_between_box">
                <div class="tit">未还款单数：</div>
                <div>{{dataListTotal.outstandOrders}}</div>
              </li>
              <li class="flex_center_between_box">
                <div class="tit">逾期单数：</div>
                <div>{{dataListTotal.overdueOrders}}</div>
              </li>
            </ul>
          </div>
        </div>
        <div class="table_common_work_echart flex_center_between_box echart_between">
          <div ref="echarts11" class="table_common_work_echart1" v-show="this.echarts18Flag"></div>
          <div class="nodata" v-show="!this.echarts18Flag">暂无数据，敬请期待…</div>
          <div class="table_common_work_echart_details" v-show="this.echarts18Flag">
            <ul>
              <li class="flex_center_between_box">
                <div class="tit">投资总金额：</div>
                <el-popover placement="top" trigger="hover" :content="filter(data.totalInvestmentAmount) + '元'" popper-class="popper_class_account">
                  <div slot="reference" class="text_overflow">{{data.totalInvestmentAmount | NumFormat}}元</div>
                </el-popover>
              </li>
              <li class="flex_center_between_box">
                <div class="tit">已还款金额：</div>
                <el-popover placement="top" trigger="hover" :content="filter(data.repaymentAmount) + '元'" popper-class="popper_class_account">
                  <div slot="reference" class="text_overflow">{{data.repaymentAmount | NumFormat}}元</div>
                </el-popover>
              </li>
              <li class="flex_center_between_box">
                <div class="tit">未还款金额：</div>
                <el-popover placement="top" trigger="hover" :content="filter(data.outstandAmount) + '元'" popper-class="popper_class_account">
                  <div slot="reference" class="text_overflow">{{data.outstandAmount | NumFormat}}元</div>
                </el-popover>
              </li>
              <li class="flex_center_between_box">
                <div class="tit">逾期金额：</div>
                <el-popover placement="top" trigger="hover" :content="filter(data.overdueOrderAmount) + '元'" popper-class="popper_class_account">
                  <div slot="reference" class="text_overflow">{{data.overdueOrderAmount | NumFormat}}元</div>
                </el-popover>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="flex_center_between_box table_common_work_echart_div">
        <div class="table_common_work_echart table_common_work_echart7 echart_between">
          <div ref="echarts12" class="table_common_work_echart1_allwidth" v-show="this.echarts19Flag"></div>
          <div class="nodata nodataTop" v-show="!this.echarts19Flag">暂无数据，敬请期待…</div>
        </div>
        <div class="table_common_work_echart table_common_work_echart7 echart_between">
          <div ref="echarts13" class="table_common_work_echart1_allwidth" v-show="this.echarts20Flag"></div>
          <div class="nodata nodataTop" v-show="!this.echarts20Flag">暂无数据，敬请期待…</div>
        </div>
      </div>
      <div class="flex_center_between_box table_common_work_echart_div">
        <div class="table_common_work_echart table_common_work_echart7">
          <div ref="echarts14" class="table_common_work_echart1_allwidth" v-show="this.echarts21Flag"></div>
          <div class="nodata nodataTop" v-show="!this.echarts21Flag">暂无数据，敬请期待…</div>
        </div>
        <div class="table_common_work_echart table_common_work_echart7">
          <div ref="echarts15" class="table_common_work_echart1_allwidth" v-show="this.echarts22Flag"></div>
          <div class="nodata nodataTop" v-show="!this.echarts22Flag">暂无数据，敬请期待…</div>
        </div>
      </div>
      <div class="workbench_table_title_total_background">
        <div class="workbench_table_title_total flex_box">
          <div class="workbench_table_title_total_bottom_dashed">
            <div class="flex_box">
              <div class="workbench_table_title_total_tit">最高投资额(元)</div>
              <div class="workbench_table_title_total_button">{{dataListTotal.maxInvest | NumFormat}}</div>
              <div class="vertical_line"></div>
            </div>
            <div class="flex_box">
              <div class="workbench_table_title_total_tit1">最低投资额(元)</div>
              <div class="workbench_table_title_total_button">{{dataListTotal.minInvest | NumFormat}}</div>
              <div class="vertical_line"></div>
            </div>
            <div class="flex_box">
              <div class="workbench_table_title_total_tit1">平均投资额(元)</div>
              <div class="workbench_table_title_total_button">{{dataListTotal.avgInvest | NumFormat}}</div>
              <div class="vertical_line"></div>
            </div>
          </div>
        </div>
        <div class="workbench_table_title_total flex_box">
          <div class="workbench_table_title_total_bottom_dashed">
            <div class="flex_box">
              <div class="workbench_table_title_total_tit">最高投资利率(%)</div>
              <div class="workbench_table_title_total_button">{{dataListTotal.maxInvestRate}}</div>
              <div class="vertical_line"></div>
            </div>
            <div class="flex_box">
              <div class="workbench_table_title_total_tit1">最低投资利率(%)</div>
              <div class="workbench_table_title_total_button">{{dataListTotal.minInvestRate}}</div>
              <div class="vertical_line"></div>
            </div>
            <div class="flex_box">
              <div class="workbench_table_title_total_tit1">平均投资利率(%)</div>
              <div class="workbench_table_title_total_button">{{dataListTotal.avgInvestRate}}</div>
              <div class="vertical_line"></div>
            </div>
          </div>
        </div>
        <div class="workbench_table_title_total flex_box">
          <div class="workbench_table_title_total_bottom_dashed">
            <div class="flex_box">
              <div class="workbench_table_title_total_tit">最高回款天数(天)</div>
              <div class="workbench_table_title_total_button">{{dataListTotal.maxCollectionDays}}</div>
              <div class="vertical_line"></div>
            </div>
            <div class="flex_box">
              <div class="workbench_table_title_total_tit1">最低回款天数(天)</div>
              <div class="workbench_table_title_total_button">{{dataListTotal.minCollectionDays}}</div>
              <div class="vertical_line"></div>
            </div>
            <div class="flex_box">
              <div class="workbench_table_title_total_tit1">平均回款天数(天)</div>
              <div class="workbench_table_title_total_button">{{dataListTotal.avgCollectionDays}}</div>
              <div class="vertical_line"></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 我的服务 -->
    <div class="table_common_work" ref="performance7">
      <div class="table_common_work_tit flex_center_between_box">
        <div>我的服务</div>
        <div class="flex_center_end_box workbench_table_title_more" @click="go(7)">
          查看更多
          <div class="iconfont icon-xiayiye-xianxing3-0"></div>
        </div>
      </div>
      <div class="flex_center_between_box table_common_work_echart_div">
        <div class="
            table_common_work_echart table_common_work_echart11
            flex_center_between_box
          ">
          <div ref="serviceEcharts" class="table_common_work_echart_Bar" v-show="this.echarts23Flag"></div>
          <div class="nodata" v-show="!this.echarts23Flag">暂无数据，敬请期待…</div>
          <div class="table_common_work_echart_details service" v-show="this.echarts23Flag">
            <ul>
              <li class="flex_center_between_box">
                <div class="tit">服务总数：</div>
                <div>{{totalService}}个</div>
              </li>
              <li class="flex_center_between_box">
                <div class="tit">已处理服务数：</div>
                <div>{{processedService}}个</div>
              </li>
              <li class="flex_center_between_box">
                <div class="tit">未处理服务数：</div>
                <div>{{untreatedService}}个</div>
              </li>
              <li class="flex_center_between_box">
                <div class="tit">即将过期服务数：</div>
                <div>{{willExpireService}}个</div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <!-- 回到顶部 -->
    <template>
      <el-backtop :visibility-height="220" :bottom="200" :right="150" class="backtop_div">
        <div class="iconfont icon-huidaodingbu"></div>
      </el-backtop>
    </template>
  </div>
</template>
<script>
import {
  coreTotal, clientAmountRatio, clientDealAmount, clientDealNum, clientDealRegion, supplierAmountRatio,
  supplierDealAmount, supplierDealNum, supplierDealRegion, getAllRatingList
} from "@/api/coreEnterprise.js";
import { financingNumAmount } from "@/api/supplier.js";
import { investAmount, investRate, investCollectionDays, investTrend } from '@/api/investor.js'
import { mixins1 } from "@/mixins/index";
import { scrollAnimation } from "@/assets/js/top";
export default {
  mixins: [mixins1],
  data() {
    return {
      index: 0,
      menuList: [
        {
          name: "我的工作台",
          path: "/system/coreEnterprise/workbench",
        },
        {
          name: "我的发票",
          path: "/system/coreEnterprise/applyFinancing",
        },
        {
          name: "我的融资",
          path: "/system/coreEnterprise/myFinancing",
        },
        {
          name: "我的供应商",
          path: "/system/coreEnterprise/mySupplier",
        },
        {
          name: "我的客户",
          path: "/system/coreEnterprise/myClient",
        },
        {
          name: "我的投资",
          path: "/system/coreEnterprise/myInvest",
        },
        {
          name: "我的获客",
          path: "/system/coreEnterprise/myCustomers",
        },
        {
          name: "我的服务",
          path: "/system/coreEnterprise/myService",
        },
        {
          name: "功能定义",
          path: "/system/coreEnterprise/functionDefinition",
        },
      ],
      //四大分类
      titData: [
        {
          tit: "我的发票",
          img: require("@/assets/img/system/supplier/whome1.png"),
          tol: "发票总数(张)",
          num: 0,
        },
        {
          tit: "我的融资",
          img: require("@/assets/img/system/supplier/whome2.png"),
          tol: "融资总数(次)",
          num: 0,
        },
        {
          tit: "我的供应商",
          img: require("@/assets/img/system/supplier/whome0.png"),
          tol: "供应商总数(个)",
          num: 0,
        },
        {
          tit: "我的客户",
          img: require("@/assets/img/system/supplier/whome4.png"),
          tol: "客户总数(个)",
          num: 0,
        },
        {
          tit: "我的投资",
          img: require("@/assets/img/system/investor/whome4.png"),
          tol: "投资总额(元)",
          num: 0,
        },
        {
          tit: "我的获客",
          img: require("@/assets/img/system/supplier/whome7.png"),
          tol: "我的获客数(个)",
          num: 0,
        },
        {
          tit: "我的服务",
          img: require("@/assets/img/system/supplier/whome6.png"),
          tol: "服务总数(个)",
          num: 0,
        },
        {
          tit: '功能定义',
          img: require('@/assets/img/system/supplier/whome8.png'),
          tol: '自定义(%)',
          num: '-',
        },
      ],
      prevImg: require('@/assets/img/prev.png'),
      nextImg: require('@/assets/img/next.png'),
      totalService: 0,
      processedService: 0,
      untreatedService: 0,
      willExpireService: 0,
      fourChart: null, //echart容器
      data: {},
      // 我的发票
      List1: [{
        value: 30,
        name: '未付款'
      }, {
        value: 70,
        name: '已付款'
      }],
      List2: [{
        value: 30,
        name: '未融资'
      }, {
        value: 70,
        name: '已融资'
      }],
      // 我的融资
      List3: [],
      List3Length: '',
      List4: [{
        value: 30,
        name: '融资失败数'
      }, {
        value: 70,
        name: '融资成功数'
      }],
      //我的供应商
      //供应商红榜
      redXList: [],
      redYList: [],
      //供应商黑榜
      blackXList: [],
      blackYList: [],
      //交易数量
      tranNumList: [],
      //交易金额
      accountList: [],
      //交易金额占比
      propList: [],
      //交易地区统计
      areaList: [],

      //我的客户
      //客户红榜
      cusRedXList: [],
      cusRedYList: [],
      //客户黑榜
      cusBlackXList: [],
      cusBlackYList: [],
      //交易数量
      cusTranNumList: [],
      //交易金额
      cusAccountList: [],
      //交易金额占比
      cusPropList: [],
      //交易地区统计
      cusAreaList: [],

      //我的服务
      serviceList: [
        {
          value: 30,
          name: "未处理",
        },
        {
          value: 70,
          name: "已处理",
        },
      ],
      fontSize: 16,
      userId: '',
      dataListTotal: {},
      DateList10: [{
        value: 0,
        name: '未还款单数'
      }, {
        value: 0,
        name: '已还款单数'
      }],
      DateList11: [{
        value: 0,
        name: '未还款金额'
      }, {
        value: 0,
        name: '已还款金额'
      }],
      DateList12: [],
      DateList13: [],
      DateList14: [],
      DateList15: [],
      echarts1Flag: true,
      echarts2Flag: true,
      echarts3Flag: true,
      echarts4Flag: true,
      echarts5Flag: true,
      echarts6Flag: true,
      echarts7Flag: true,
      echarts8Flag: true,
      echarts9Flag: true,
      echarts10Flag: true,
      echarts11Flag: true,
      echarts12Flag: true,
      echarts13Flag: true,
      echarts14Flag: true,
      echarts15Flag: true,
      echarts16Flag: true,
      echarts17Flag: true,
      echarts18Flag: true,
      echarts19Flag: true,
      echarts20Flag: true,
      echarts21Flag: true,
      echarts22Flag: true,
      echarts23Flag: true,
      List7Length: '',
      List8Length: '',
      List9Length: '',
      List10Length: '',
      List13Length: '',
      List14Length: '',
      List15Length: '',
      List16Length: '',
      List19Length: '',
      List20Length: '',
      List21Length: '',
      List22Length: '',
    }
  },
  mounted() {
    this.tableWidth()
    this.resizeFn();
  },
  created() {
    this.getAllData()
  },
  methods: {
    //切换头部tab
    go(index) {
      this.$router.push(this.menuList[index].path);
    },
    onResearch(index) {
      this.$nextTick(function () {
        if (index === 1) {
          const top = this.$refs.performance1.offsetTop
          const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
          scrollAnimation(scrollTop, top)
        } else if (index === 2) {
          const top = this.$refs.performance2.offsetTop
          const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
          scrollAnimation(scrollTop, top)
        } else if (index === 3) {
          const top = this.$refs.performance3.offsetTop;
          const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
          scrollAnimation(scrollTop, top);
        } else if (index === 4) {
          const top = this.$refs.performance4.offsetTop;
          const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
          scrollAnimation(scrollTop, top);
        } else if (index === 5) {
          const top = this.$refs.performance5.offsetTop;
          const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
          scrollAnimation(scrollTop, top);
        } else if (index === 6) {
          this.go(6)
        } else if (index === 7) {
          const top = this.$refs.performance7.offsetTop;
          const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
          scrollAnimation(scrollTop, top);
        } else if (index === 8) {
          this.go(8)
        }
      });
    },
    getAllData() {
      this.getCoreTotal()

      this.getSupplierAmountRatio()
      this.getClientAmountRatio()

      this.getSupplierDealAmount()
      this.getClientDealAmount()

      this.getSupplierDealNum()
      this.getClientDealNum()

      this.getSupplierDealRegion()
      this.getClientDealRegion()

      this.getAllRatingList()

    },
    //统计
    async getCoreTotal() {
      let res = await coreTotal()
      if (res && res.code == 200) {
        this.data = res.data
        this.List1[0].value = this.data.totalUnpaid
        this.List1[1].value = this.data.totalPaid
        this.List2[0].value = this.data.unfundedQuantity
        this.List2[1].value = this.data.quantityFinanced
        this.List4[0].value = this.data.failFinancingNum
        this.List4[1].value = this.data.successfulFinancingNum
        let data = await financingNumAmount()
        this.List3Length = data.data.length
        data.data.forEach((item, index) => {
          this.List3[index] = []
          this.List3[index][0] = item.num
          this.List3[index][1] = item.amount
        });

        this.dataListTotal = res.data
        this.DateList10[0].value = res.data.outstandOrders
        this.DateList10[1].value = res.data.repaymentOrders
        this.DateList11[0].value = res.data.outstandAmount
        this.DateList11[1].value = res.data.repaymentAmount

        let obj = res.data
        this.titData[0].num = obj.totalInvoiceQuantity;
        this.titData[1].num = obj.totalFinancing;
        this.titData[2].num = obj.totalSupplier;
        this.titData[3].num = obj.totalCustomers;
        this.titData[4].num = this.filter(obj.totalInvestmentAmount);
        this.titData[5].num = obj.getCust;
        this.titData[6].num = obj.totalService;
        this.titData[7].num = obj.functionPercent;


        this.totalService = obj.totalService
        this.processedService = obj.processedService
        this.untreatedService = obj.untreatedService
        this.willExpireService = obj.willExpireService
        this.serviceList[0].value = obj.untreatedService
        this.serviceList[1].value = obj.processedService
        this.serviceEcharts(this.fontSize, this.horGapLegendHeight, this.pieLegendWidth, this.eLength);

        this.$nextTick(() => {
          this.echarts1(this.pieFontSize, this.pieFontSize1, this.legendWidth, this.lengendJg)
          this.echarts2(this.pieFontSize, this.pieFontSize1)
          this.echarts3(this.pieFontSize, this.marginHeight, this.pieFontSize2)
          this.echarts4(this.pieFontSize, this.pieFontSize1)
          this.echarts10(this.pieFontSize, this.pieFontSize1)
          this.echarts11(this.pieFontSize, this.pieFontSize1)
          this.echarts12(this.pieFontSize, this.pieFontSize1, this.yLineHeight, this.marginHeight, this.gridbottom)
          this.echarts13(this.pieFontSize, this.pieFontSize1, this.yLineHeight, this.marginHeight, this.gridbottom)
          this.echarts14(this.pieFontSize, this.pieFontSize1, this.yLineHeight, this.marginHeight, this.gridbottom)
          this.echarts15(this.pieFontSize, this.pieFontSize1, this.yLineHeightT)
        })
      }
    },
    //我的发票
    echarts1(pieFontSize, pieFontSize1, legendWidth, lengendJg) {
      if (this.List1[0].value == 0 && this.List1[1].value == 0) {
        this.echarts1Flag = false
      }
      if (this.fourChart !== null) {
        this.fourChart.dispose()
      }
      this.fourChart = this.$echarts.init(this.$refs.echarts1)
      let that = this
      const option = {
        tooltip: {
          borderWidth: 0,
          textStyle: {
            color: '#162747',
          },
          formatter: function (params) {
            params['value'] = that.filter(params['value'])
            if (params.value > 1) {
              return params['name'] + '：' + params['value'] + '元'
            } else {
              return params['name'] + '：' + params['value'] + '元'
            }
          }
        },
        legend: {
          orient: 'horizontal',
          left: '0%',
          itemGap: lengendJg,
          itemWidth: legendWidth,
          itemHeight: legendWidth,
        },
        series: [
          {
            z: '2',
            type: 'pie',
            clockwise: false,
            startAngle: 72,
            center: ['50%', '55%'],
            radius: [0, '70%'],
            emphasis: {
              itemStyle: {
                shadowBlur: 5,
                shadowOffsetX: 0,
                shadowOffsetY: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            },
            label: {
              show: true,
              fontSize: pieFontSize1,
              position: 'inside',
              borderWidth: 0,
              color: '#fff',
              formatter: function (params) { return (params.percent.toFixed(2) + '%') }
            },
            labelLine: {
              show: true,
              length: 10,
              length2: 30
            },
            itemStyle: {
              color: function (params) {
                const colorList = [
                  '#FF9B15',
                  '#339C9B'
                ]
                return colorList[params.dataIndex]
              },
            },
            data: this.List1
          },
          {
            z: '1',
            type: 'pie',
            clockwise: false,
            startAngle: 72,
            center: ['50%', '55%'],
            radius: [0, '70%'],
            label: {
              show: true,
              fontSize: pieFontSize,
              borderWidth: 0,
              color: 'inherit',
              formatter: function (params) {
                return (params['name'])
              }

            },
            labelLine: {
              show: true,
              length: 10,
              length2: 30
            },
            itemStyle: {
              show: false,
              color: function (params) {
                const colorList = [
                  '#FF9B15',
                  '#339C9B'
                ]
                return colorList[params.dataIndex]
              },
            },
            data: this.List1
          }
        ]
      }
      this.fourChart.setOption(option)
    },
    echarts2(pieFontSize, pieFontSize1) {
      if (this.List2[0].value == 0 && this.List2[1].value == 0) {
        this.echarts2Flag = false
      }
      this.fourChart = this.$echarts.init(this.$refs.echarts2)
      const option = {
        tooltip: {
          borderWidth: 0,
          textStyle: {
            color: '#162747',
          },
          formatter: function (params) {
            if (params.value > 1) {
              return params['name'] + '：' + params['value'] + '张'
            } else {
              return params['name'] + '：' + params['value'] + '张'
            }
          }
        },
        legend: {
          orient: 'horizontal',
          left: '0%',
          itemGap: 20,
          itemWidth: 15,
          itemHeight: 15
        },
        series: [
          {
            z: '2',
            type: 'pie',
            clockwise: false,
            startAngle: 72,
            center: ['50%', '55%'],
            radius: [0, '70%'],
            emphasis: {
              itemStyle: {
                shadowBlur: 5,
                shadowOffsetX: 0,
                shadowOffsetY: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            },
            label: {
              show: true,
              fontSize: pieFontSize1,
              position: 'inside',
              borderWidth: 0,
              color: '#fff',
              formatter: function (params) { return (params.percent.toFixed(0) + '%') }
            },
            labelLine: {
              show: true,
              length: 10,
              length2: 30
            },
            itemStyle: {
              color: function (params) {
                const colorList = [
                  '#FF9B15',
                  '#339C9B'
                ]
                return colorList[params.dataIndex]
              },
            },
            data: this.List2
          },
          {
            z: '1',
            type: 'pie',
            clockwise: false,
            startAngle: 72,
            center: ['50%', '55%'],
            radius: [0, '70%'],
            label: {
              show: true,
              fontSize: pieFontSize,
              borderWidth: 0,
              color: 'inherit',
              formatter: function (params) { return (params['name']) }
            },
            labelLine: {
              show: true,
              length: 10,
              length2: 30
            },
            itemStyle: {
              show: false,
              color: function (params) {
                const colorList = [
                  '#FF9B15',
                  '#339C9B'
                ]
                return colorList[params.dataIndex]
              },
            },
            data: this.List2
          }
        ]
      }
      this.fourChart.setOption(option)
    },

    // 我的融资
    echarts3(pieFontSize, marginHeight) {
      if (this.List3Length == 0) {
        this.echarts3Flag = false
      }
      this.fourChart = this.$echarts.init(this.$refs.echarts3)
      let that = this
      const option = {
        xAxis: {
          type: 'category',
          axisLabel: {
            interval: 0,
            rotate: 60,
            margin: marginHeight,
            textStyle: {
              color: '#92A2BC',
              fontSize: pieFontSize
            },
            formatter: function (value) {
              console.log(value.length)
              if (value.length < 10) {
                return value
              } else {
                return value.slice(0, 9) + '...'
              }
            }
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: '#92A2BC'
            }
          },
          axisTick: {
            show: false
          }
        },
        yAxis: {
          type: 'value',
          axisLine: {
            show: true,
            lineStyle: {
              color: '#92A2BC'
            }
          },
          axisTick: {
            show: true,
            length: 3
          },
          name: '单位：元',
          nameTextStyle: {
            color: '#162747',
            fontSize: pieFontSize
          },
          axisLabel: {
            fontSize: pieFontSize,
            fontFamily: 'Microsoft YaHei',
            formatter: function (value) {
              if (value.toString().length < 6) {
                return value.toString()
              } else {
                return value.toString().slice(0, 5) + '...'
              }
            }
          },
        },
        grid: {
          left: '14%',
          top: '18%',
          bottom: '32%'
        },
        tooltip: {
          borderWidth: 0,
          textStyle: {
            color: '#162747',
          },
          formatter: function (params) {
            params.data[1] = that.filter(params.data[1])
            if (params.value > 1) {
              return params.data[0] + ':' + params.data[1] + '元'
            } else {
              return params.data[0] + ':' + params.data[1] + '元'
            }
          }
        },
        series: [
          {
            type: 'bar',
            center: ['50%', '50%'],
            radius: [0, '70%'],
            barWidth: '12',
            itemStyle: {
              normal: {
                color: '#339C9B',
                barBorderRadius: [100, 100, 0, 0]
              },
            },
            data: this.List3
          }
        ]
      }
      this.fourChart.setOption(option)
      // this.fourChart.resize()
    },
    echarts4(pieFontSize, pieFontSize1) {
      if (this.List4[0].value == 0 && this.List4[1].value == 0) {
        this.echarts4Flag = false
      }
      this.fourChart = this.$echarts.init(this.$refs.echarts4)
      const option = {
        tooltip: {
          borderWidth: 0,
          textStyle: {
            color: '#162747',
          },
          formatter: function (params) {
            if (params.value > 1) {
              return params['name'] + '：' + params['value'] + '个'
            } else {
              return params['name'] + '：' + params['value'] + '个'
            }
          }
        },
        legend: {
          orient: 'horizontal',
          left: '0%',
          itemGap: 20,
          itemWidth: 15,
          itemHeight: 15
        },
        series: [
          {
            z: '2',
            type: 'pie',
            clockwise: false,
            startAngle: 72,
            center: ['50%', '55%'],
            radius: [0, '60%'],
            emphasis: {
              itemStyle: {
                shadowBlur: 5,
                shadowOffsetX: 0,
                shadowOffsetY: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            },
            label: {
              show: true,
              fontSize: pieFontSize1,
              position: 'inside',
              borderWidth: 0,
              color: '#fff',
              formatter: function (params) { return (params.percent.toFixed(0) + '%') }
            },
            labelLine: {
              show: true,
              length: 10,
              length2: 30
            },
            itemStyle: {
              color: function (params) {
                const colorList = [
                  '#FF9B15',
                  '#339C9B'
                ]
                return colorList[params.dataIndex]
              },
            },
            data: this.List4
          },
          {
            z: '1',
            type: 'pie',
            clockwise: false,
            startAngle: 72,
            center: ['50%', '55%'],
            radius: [0, '60%'],
            label: {
              show: true,
              fontSize: pieFontSize,
              borderWidth: 0,
              color: 'inherit',
              formatter: function (params) { return (params['name']) }
            },
            labelLine: {
              show: true,
              length: 10,
              length2: 30
            },
            itemStyle: {
              show: false,
              color: function (params) {
                const colorList = [
                  '#FF9B15',
                  '#339C9B'
                ]
                return colorList[params.dataIndex]
              },
            },
            data: this.List4
          }
        ]
      }
      this.fourChart.setOption(option)
    },

    //我的投资
    echarts10(pieFontSize, pieFontSize1) {
      if (this.DateList10[0].value == 0 && this.DateList10[1].value == 0) {
        this.echarts17Flag = false
      }
      this.fourChart = this.$echarts.init(this.$refs.echarts10)
      const option = {
        tooltip: {
          borderWidth: 0,
          textStyle: {
            color: '#162747',
          },
          formatter: function (params) {
            if (params.value > 1) {
              return params['name'] + '：' + params['value'] + '个'
            } else {
              return params['name'] + '：' + params['value'] + '个'
            }
          }
        },
        legend: {
          orient: 'horizontal',
          left: '0%',
          itemGap: 20,
          itemWidth: 15,
          itemHeight: 15
        },
        series: [
          {
            z: '2',
            type: 'pie',
            clockwise: false,
            startAngle: 72,
            center: ['50%', '55%'],
            radius: [0, '70%'],
            emphasis: {
              itemStyle: {
                shadowBlur: 5,
                shadowOffsetX: 0,
                shadowOffsetY: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            },
            label: {
              show: true,
              fontSize: pieFontSize1,
              position: 'inside',
              borderWidth: 0,
              color: '#fff',
              formatter: function (params) { return (params.percent.toFixed(0) + '%') }
            },
            labelLine: {
              show: true,
              length: 10,
              length2: 30
            },
            itemStyle: {
              color: function (params) {
                const colorList = [
                  '#FF9B15',
                  '#339C9B'
                ]
                return colorList[params.dataIndex]
              },
            },
            data: this.DateList10
          },
          {
            z: '1',
            type: 'pie',
            clockwise: false,
            startAngle: 72,
            center: ['50%', '55%'],
            radius: [0, '70%'],
            label: {
              show: true,
              fontSize: pieFontSize,
              borderWidth: 0,
              color: 'inherit',
              formatter: function (params) { return (params['name']) }
            },
            labelLine: {
              show: true,
              length: 10,
              length2: 30
            },
            itemStyle: {
              color: function (params) {
                const colorList = [
                  '#FF9B15',
                  '#339C9B'
                ]
                return colorList[params.dataIndex]
              },
            },
            data: this.DateList10
          }
        ]
      }
      this.fourChart.setOption(option)
      // this.fourChart.resize()
    },
    echarts11(pieFontSize, pieFontSize1) {
      if (this.DateList11[0].value == 0 && this.DateList11[0].value == 0) {
        this.echarts18Flag = false
      }
      this.fourChart = this.$echarts.init(this.$refs.echarts11)
      const option = {
        tooltip: {
          borderWidth: 0,
          textStyle: {
            color: '#162747',
          },
          formatter: function (params) {
            if (params.value > 1) {
              return params['name'] + '：' + params['value'] + '元'
            } else {
              return params['name'] + '：' + params['value'] + '元'
            }
          }
        },
        legend: {
          orient: 'horizontal',
          left: '0%',
          itemGap: 20,
          itemWidth: 15,
          itemHeight: 15
        },
        series: [
          {
            z: '2',
            type: 'pie',
            clockwise: false,
            startAngle: 72,
            center: ['50%', '55%'],
            radius: [0, '70%'],
            emphasis: {
              itemStyle: {
                shadowBlur: 5,
                shadowOffsetX: 0,
                shadowOffsetY: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            },
            label: {
              show: true,
              fontSize: pieFontSize1,
              position: 'inside',
              borderWidth: 0,
              color: '#fff',
              formatter: function (params) { return (params.percent.toFixed(0) + '%') }
            },
            labelLine: {
              show: true,
              length: 10,
              length2: 30
            },
            itemStyle: {
              color: function (params) {
                const colorList = [
                  '#FF9B15',
                  '#339C9B'
                ]
                return colorList[params.dataIndex]
              },
            },
            data: this.DateList11
          },
          {
            z: '1',
            type: 'pie',
            clockwise: false,
            startAngle: 72,
            center: ['50%', '55%'],
            radius: [0, '70%'],
            label: {
              show: true,
              fontSize: pieFontSize,
              borderWidth: 0,
              color: 'inherit',
              formatter: function (params) { return (params['name']) }
            },
            labelLine: {
              show: true,
              length: 10,
              length2: 30
            },
            itemStyle: {
              color: function (params) {
                const colorList = [
                  '#FF9B15',
                  '#339C9B'
                ]
                return colorList[params.dataIndex]
              },
            },
            data: this.DateList11
          }
        ]
      }
      this.fourChart.setOption(option)
      // this.fourChart.resize()
    },
    async echarts12(pieFontSize, pieFontSize1, yLineHeight, marginHeight, gridbottom) {
      let res = await investAmount()
      if (res.data.length == 0) {
        this.echarts19Flag = false
      }
      res.data.forEach((item, index) => {
        this.DateList12[index] = []
        this.DateList12[index][0] = item.name
        this.DateList12[index][1] = item.amount
      })
      this.fourChart = this.$echarts.init(this.$refs.echarts12)
      this.echartsbar(this.DateList12, '我的投资金额', '元', pieFontSize, pieFontSize1, yLineHeight, marginHeight, gridbottom)
    },
    async echarts13(pieFontSize, pieFontSize1, yLineHeight, marginHeight, gridbottom) {
      let res = await investRate()
      if (res.data.length == 0) {
        this.echarts20Flag = false
      }
      res.data.forEach((item, index) => {
        this.DateList13[index] = []
        this.DateList13[index][0] = item.name
        this.DateList13[index][1] = item.rate
      })
      this.fourChart = this.$echarts.init(this.$refs.echarts13)
      this.echartsbar(this.DateList13, '我的投资利率', '', pieFontSize, pieFontSize1, yLineHeight, marginHeight, gridbottom)
    },
    async echarts14(pieFontSize, pieFontSize1, yLineHeight, marginHeight, gridbottom) {
      let res = await investCollectionDays()
      if (res.data.length == 0) {
        this.echarts21Flag = false
      }
      res.data.forEach((item, index) => {
        this.DateList14[index] = []
        this.DateList14[index][0] = item.name
        this.DateList14[index][1] = item.days
      })
      this.fourChart = this.$echarts.init(this.$refs.echarts14)
      this.echartsbar(this.DateList14, '我的回款天数', '天数', pieFontSize, pieFontSize1, yLineHeight, marginHeight, gridbottom)
    },
    async echarts15(pieFontSize, pieFontSize1, yLineHeight) {
      let res = await investTrend()
      console.log(res.data.length)
      if (res.data.length == 0) {
        this.echarts22Flag = false
      }
      this.DateList15[0] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      this.DateList15[1] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      res.data.forEach((item, index) => {
        console.log(item.date - 1)
        this.DateList15[0][item.date - 1] = item.num
        this.DateList15[1][item.date - 1] = item.amount
      })
      console.log(this.DateList15)
      this.fourChart = this.$echarts.init(this.$refs.echarts15)
      const option = {
        title: {
          text: '投资趋势',
          textStyle: {
            color: '#162747',
            fontFamily: "微软雅黑",
            fontWeight: 'normal',
            fontSize: pieFontSize1
          }
        },
        legend: {
          show: true,
          orient: 'horizontal',
          right: '2%',
          itemGap: 20,
          itemWidth: 15,
          itemHeight: 15,
          icon: 'roundRect',
          data: ['投资单数', '投资额']
        },
        xAxis: {
          type: 'category',
          axisLabel: {
            margin: 8,
            textStyle: {
              color: '#92A2BC',
              fontSize: pieFontSize
            }
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: '#92A2BC'
            }
          },
          axisTick: {
            show: false
          },
          data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月']
        },
        yAxis: [{
          type: 'value',
          position: 'right',
          axisLabel: {
            show: true,
            fontFamily: 'Microsoft YaHei',
            fontSize: pieFontSize,
            formatter: '{value}'
          },
          splitLine: false,
          axisTick: {
            show: false
          },
          name: '元',
          nameTextStyle: {
            lineHeight: yLineHeight
          },
        },
        {
          type: 'value',
          position: 'left',
          axisLabel: {
            show: true,
            fontFamily: 'Microsoft YaHei',
            fontSize: pieFontSize,
            formatter: '{value}'
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: '#92A2BC'
            }
          },
          axisTick: {
            show: true,
            length: 3
          },
          name: '单数',
          nameTextStyle: {
            lineHeight: yLineHeight
          },
        }
        ],
        grid: {
          left: '10%',
          right: '10%',
          top: '23%',
          bottom: '9%',
        },
        tooltip: {
          trigger: 'axis',
          textStyle: {
            color: '#162747',
          },
          axisPointer: {
            type: 'cross'
          }
        },
        series: [
          {
            name: '投资单数',
            type: 'line',
            smooth: true,
            center: ['50%', '50%'],
            radius: [0, '70%'],
            barWidth: '12',
            itemStyle: {
              normal: {
                color: '#FF9B15',
                barBorderRadius: [100, 100, 0, 0]
              },
            },
            data: this.DateList15[0]
          },
          {
            name: '投资额',
            yAxisIndex: 1,
            type: 'bar',
            center: ['50%', '50%'],
            radius: [0, '70%'],
            barWidth: '12',
            itemStyle: {
              normal: {
                color: '#339C9B',
                barBorderRadius: [100, 100, 0, 0]
              },
            },
            data: this.DateList15[1]
          }
        ]
      }
      this.fourChart.setOption(option)
    },

    //红黑榜
    async getAllRatingList() {

      let res = await getAllRatingList()
      console.log(res)
      if (res && res.code == 200) {
        let obj1 = res.data.blackCustoms
        obj1.forEach((item, index) => {
          this.cusBlackXList[index] = {}
          this.cusBlackYList[index] = {}
          this.cusBlackXList[index] = item.score
          this.cusBlackYList[index] = item.name
        });
        let obj2 = res.data.blackSupply
        obj2.forEach((item, index) => {
          this.blackXList[index] = {}
          this.blackYList[index] = {}
          this.blackXList[index] = item.score
          this.blackYList[index] = item.name
        });
        let obj3 = res.data.redCustoms
        obj3.forEach((item, index) => {
          this.cusRedXList[index] = {}
          this.cusRedYList[index] = {}
          this.cusRedXList[index] = item.score
          this.cusRedYList[index] = item.name
        });
        let obj4 = res.data.redSupply
        obj4.forEach((item, index) => {
          this.redXList[index] = {}
          this.redYList[index] = {}
          this.redXList[index] = item.score
          this.redYList[index] = item.name
        });
        this.redEcharts(this.fontSize, this.barLaWidth, this.yLineHeight, this.pieFontSize, this.barDistance);
        this.blackEcharts(this.fontSize, this.barLaWidth, this.yLineHeight, this.pieFontSize, this.barDistance);
        this.cusRedEcharts(this.fontSize, this.barLaWidth, this.yLineHeight, this.pieFontSize, this.barDistance);
        this.cusBlackEcharts(this.fontSize, this.barLaWidth, this.yLineHeight, this.pieFontSize, this.barDistance);
        console.log(this.redYList)
      }
    },
    //供应商
    //1.客户交易金额占比列表
    async getSupplierAmountRatio() {
      let res = await supplierAmountRatio()
      this.List9Length = res.data.length
      if (res && res.code == 200) {
        let obj = res.data
        obj.forEach((item, index) => {
          this.propList[index] = {}
          this.propList[index].value = item.ratio
          this.propList[index].name = item.name
        });
        this.propEcharts(this.fontSize, this.gapLegendWidth, this.pieLegendWidth, this.pieFontSize, this.eLength);
      }

    },
    //2.
    async getSupplierDealAmount() {
      let res = await supplierDealAmount()
      this.List8Length = res.data.length
      if (res && res.code == 200) {
        let obj = res.data
        obj.forEach((item, index) => {
          this.accountList[index] = []
          this.accountList[index][0] = item.name
          this.accountList[index][1] = item.amount
        });
        this.accountEcharts(this.fontSize, this.pieFontSize, this.barSmWidth, this.marginHeight);
      }
    },
    //3.
    async getSupplierDealNum() {
      let res = await supplierDealNum()
      console.log(res)
      this.List7Length = res.data.length
      if (res && res.code == 200) {
        let obj = res.data
        obj.forEach((item, index) => {
          this.tranNumList[index] = []
          this.tranNumList[index][0] = item.name
          this.tranNumList[index][1] = item.num
        });
        this.tranNumEcharts(this.fontSize, this.pieFontSize, this.barSmWidth, this.marginHeight);
      }
    },
    //4.
    async getSupplierDealRegion() {
      let res = await supplierDealRegion()
      this.List10Length = res.data.length
      if (res && res.code == 200) {
        let obj = res.data
        obj.forEach((item, index) => {
          this.areaList[index] = []
          this.areaList[index][0] = item.name
          this.areaList[index][1] = item.num
        });
        this.areaEcharts(this.fontSize, this.barSmWidth, this.pieFontSize);
      }
    },
    //客户
    //1.核心企业客户交易金额占比列表
    async getClientAmountRatio() {
      let res = await clientAmountRatio()
      this.List15Length = res.data.length
      if (res && res.code == 200) {
        let obj = res.data
        obj.forEach((item, index) => {
          this.cusPropList[index] = {}
          this.cusPropList[index].value = item.ratio
          this.cusPropList[index].name = item.name
        });
        this.cusPropEcharts(this.fontSize, this.gapLegendWidth, this.pieLegendWidth, this.pieFontSize, this.eLength);
      }
    },
    //2.
    async getClientDealAmount() {
      let res = await clientDealAmount()
      this.List14Length = res.data.length
      if (res && res.code == 200) {
        let obj = res.data
        obj.forEach((item, index) => {
          this.cusAccountList[index] = []
          this.cusAccountList[index][0] = item.name
          this.cusAccountList[index][1] = item.amount
        });
        this.cusAccountEcharts(this.fontSize, this.pieFontSize, this.barSmWidth, this.marginHeight);
      }
    },
    //3.
    async getClientDealNum() {
      let res = await clientDealNum()
      this.List13Length = res.data.length
      if (res && res.code == 200) {
        let obj = res.data
        obj.forEach((item, index) => {
          this.cusTranNumList[index] = []
          this.cusTranNumList[index][0] = item.name
          this.cusTranNumList[index][1] = item.num
        });
        this.cusTranNumEcharts(this.fontSize, this.pieFontSize, this.barSmWidth, this.marginHeight);
      }
    },
    //4.
    async getClientDealRegion() {
      let res = await clientDealRegion()
      this.List16Length = res.data.length
      if (res && res.code == 200) {
        let obj = res.data
        obj.forEach((item, index) => {
          this.cusAreaList[index] = []
          this.cusAreaList[index][0] = item.name
          this.cusAreaList[index][1] = item.num
        });
        this.cusAreaEcharts(this.fontSize, this.barSmWidth, this.pieFontSize);
      }
    },
    //供应商红榜
    redEcharts(fontSize, barLaWidth, yLineHeight, pieFontSize, barDistance) {
      console.log(this.redXList)
      if (this.redXList.length == 0 && this.redYList == 0) {
        this.echarts5Flag = false
      }
      this.myChart = this.$echarts.init(this.$refs.redEcharts);
      //const labelOption =labOptions()
      const option = this.redOptions(
        "供应商红榜",
        fontSize,
        barLaWidth,
        this.redXList,
        this.redYList,
        yLineHeight,
        pieFontSize,
        barDistance
      );
      this.myChart.setOption(option, true);
    },

    //供应商黑榜
    blackEcharts(fontSize, barLaWidth, yLineHeight, pieFontSize, barDistance) {
      if (this.blackXList.length == 0 && this.blackYList == 0) {
        this.echarts6Flag = false
      }
      let myChart = this.$echarts.getInstanceByDom(this.$refs.blackEcharts);
      if (myChart == null) {
        myChart = this.$echarts.init(this.$refs.blackEcharts);
      }
      const option = this.redOptions(
        "供应商黑榜",
        fontSize,
        barLaWidth,
        this.blackXList,
        this.blackYList,
        yLineHeight,
        pieFontSize,
        barDistance
      );
      myChart.setOption(option, true);
    },

    //交易数量
    tranNumEcharts(fontSize, pieFontSize, barSmWidth, marginHeight) {
      console.log(this.List7Length)
      if (this.List7Length == 0) {
        this.echarts7Flag = false
      }
      let myChart = this.$echarts.getInstanceByDom(this.$refs.tranNumEcharts);
      if (myChart == null) {
        myChart = this.$echarts.init(this.$refs.tranNumEcharts);
      }
      const option = this.tranOptions(
        "交易数量",
        fontSize,
        pieFontSize,
        barSmWidth,
        marginHeight,
        this.tranNumList,
        "笔数",
        "笔"
      );
      myChart.setOption(option);
    },

    //交易金额
    accountEcharts(fontSize, pieFontSize, barSmWidth, marginHeight) {
      if (this.List8Length == 0) {
        this.echarts8Flag = false
      }
      let myChart = this.$echarts.getInstanceByDom(this.$refs.accountEcharts);
      if (myChart == null) {
        myChart = this.$echarts.init(this.$refs.accountEcharts);
      }
      const option = this.tranOptions(
        "交易金额",
        fontSize,
        pieFontSize,
        barSmWidth,
        marginHeight,
        this.accountList,
        "元",
        "元"
      );
      myChart.setOption(option);
    },
    //交易金额占比
    propEcharts(fontSize, gapLegendWidth, pieLegendWidth, pieFontSize, eLength) {
      if (this.List9Length == 0) {
        this.echarts9Flag = false
      }
      let myChart = this.$echarts.getInstanceByDom(this.$refs.propEcharts);
      if (myChart == null) {
        myChart = this.$echarts.init(this.$refs.propEcharts);
      }
      const option = this.moneyOption(fontSize, gapLegendWidth, pieLegendWidth, this.propList, pieFontSize, eLength);
      myChart.setOption(option);
    },
    //交易地区统计
    areaEcharts(fontSize, barSmWidth, pieFontSize) {
      if (this.List10Length == 0) {
        this.echarts10Flag = false
      }
      let myChart = this.$echarts.getInstanceByDom(this.$refs.areaEcharts);
      if (myChart == null) {
        myChart = this.$echarts.init(this.$refs.areaEcharts);
      }
      const option = this.areaOption(fontSize, barSmWidth, this.areaList, pieFontSize);
      myChart.setOption(option);
    },
    //我的客户
    //客户红榜
    cusRedEcharts(fontSize, barLaWidth, yLineHeight, pieFontSize, barDistance) {
      if (this.cusRedXList.length == 0 && this.cusRedYList == 0) {
        this.echarts11Flag = false
      }
      let myChart = this.$echarts.getInstanceByDom(this.$refs.cusRedEcharts);
      if (myChart == null) {
        myChart = this.$echarts.init(this.$refs.cusRedEcharts);
      }
      const option = this.redOptions(
        "客户红榜",
        fontSize,
        barLaWidth,
        this.cusRedXList,
        this.cusRedYList,
        yLineHeight,
        pieFontSize,
        barDistance
      );
      myChart.setOption(option, true);
    },
    //客户黑榜
    cusBlackEcharts(fontSize, barLaWidth, yLineHeight, pieFontSize, barDistance) {
      if (this.cusBlackXList.length == 0 && this.cusBlackYList.length == 0) {
        this.echarts12Flag = false
      }
      let myChart = this.$echarts.getInstanceByDom(this.$refs.cusBlackEcharts);
      if (myChart == null) {
        myChart = this.$echarts.init(this.$refs.cusBlackEcharts);
      }
      const option = this.redOptions(
        "客户黑榜",
        fontSize,
        barLaWidth,
        this.cusBlackXList,
        this.cusBlackYList,
        yLineHeight,
        pieFontSize,
        barDistance
      );
      myChart.setOption(option, true);
    },

    //交易数量
    cusTranNumEcharts(fontSize, pieFontSize, barSmWidth, marginHeight) {
      if (this.List13Length == 0) {
        this.echarts13Flag = false
      }
      let myChart = this.$echarts.getInstanceByDom(
        this.$refs.cusTranNumEcharts
      );
      if (myChart == null) {
        myChart = this.$echarts.init(this.$refs.cusTranNumEcharts);
      }
      const option = this.tranOptions(
        "交易数量",
        fontSize,
        pieFontSize,
        barSmWidth,
        marginHeight,
        this.cusTranNumList,
        "笔数",
        "笔"
      );
      myChart.setOption(option);
    },

    //交易金额
    cusAccountEcharts(fontSize, pieFontSize, barSmWidth, marginHeight) {
      if (this.List14Length == 0) {
        this.echarts14Flag = false
      }
      let myChart = this.$echarts.getInstanceByDom(
        this.$refs.cusAccountEcharts
      );
      if (myChart == null) {
        myChart = this.$echarts.init(this.$refs.cusAccountEcharts);
      }
      const option = this.tranOptions(
        "交易金额",
        fontSize,
        pieFontSize,
        barSmWidth,
        marginHeight,
        this.cusAccountList,
        "元",
        "元"
      );
      myChart.setOption(option);
    },
    //交易金额占比
    cusPropEcharts(fontSize, gapLegendWidth, pieLegendWidth, pieFontSize, eLength) {
      if (this.List15Length == 0) {
        this.echarts15Flag = false
      }
      let myChart = this.$echarts.getInstanceByDom(this.$refs.cusPropEcharts);
      if (myChart == null) {
        myChart = this.$echarts.init(this.$refs.cusPropEcharts);
      }
      const option = this.moneyOption(fontSize, gapLegendWidth, pieLegendWidth, this.cusPropList, pieFontSize, eLength);
      myChart.setOption(option);
    },
    //交易地区统计
    cusAreaEcharts(fontSize, barSmWidth, pieFontSize) {
      if (this.List16Length == 0) {
        this.echarts16Flag = false
      }
      let myChart = this.$echarts.getInstanceByDom(this.$refs.cusAreaEcharts);
      if (myChart == null) {
        myChart = this.$echarts.init(this.$refs.cusAreaEcharts);
      }
      const option = this.areaOption(fontSize, barSmWidth, this.cusAreaList, pieFontSize);
      myChart.setOption(option);
    },

    //我的服务
    serviceEcharts(fontSize, horGapLegendHeight, pieLegendWidth, eLength) {
      if (this.serviceList[0].value == 0 && this.serviceList[1].value == 0) {
        this.echarts23Flag = false
      }
      let myChart = this.$echarts.getInstanceByDom(this.$refs.serviceEcharts);
      if (myChart == null) {
        myChart = this.$echarts.init(this.$refs.serviceEcharts);
      }
      const option = {
        tooltip: {
          borderWidth: 0,
          formatter: function (params) {
            if (params.value > 1) {
              return params["name"] + "服务数" + params["value"] + "个";
            } else {
              return params["name"] + "服务数" + params["value"] + "个";
            }
          },
        },
        legend: {
          orient: "horizontal",
          left: "0%",
          itemGap: horGapLegendHeight,
          itemWidth: pieLegendWidth,
          itemHeight: pieLegendWidth,
        },
        series: [
          {
            z: "2",
            type: "pie",
            clockwise: false,
            startAngle: 72,
            center: ["50%", "55%"],
            radius: [0, "70%"],
            emphasis: {
              itemStyle: {
                shadowBlur: 5,
                shadowOffsetX: 0,
                shadowOffsetY: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
            label: {
              show: true,
              fontSize: fontSize,
              position: "inside",
              borderWidth: 0,
              color: "#fff",
              formatter: function (params) {
                return params.percent + "%";
              },
            },
            labelLine: {
              show: true,
              length: 10,
              length2: eLength,
            },
            itemStyle: {
              color: function (params) {
                const colorList = ["#FF9B15", "#339C9B"];
                return colorList[params.dataIndex];
              },
            },
            data: this.serviceList,
          },
          {
            z: "1",
            type: "pie",
            clockwise: false,
            startAngle: 72,
            center: ["50%", "55%"],
            radius: [0, "70%"],
            label: {
              show: true,
              fontSize: fontSize,
              borderWidth: 0,
              color: "inherit",
              formatter: function (params) {
                return params["name"];
              },
            },
            labelLine: {
              show: true,
              length: 10,
              length2: eLength,
            },
            itemStyle: {
              show: false,
              color: function (params) {
                const colorList = ["#FF9B15", "#339C9B"];
                return colorList[params.dataIndex];
              },
            },
            data: this.serviceList,
          },
        ],
      };
      myChart.setOption(option);
    },

    //echart中option的共同方法
    //供应商红 + 黑榜单,客户红 + 黑榜单
    redOptions(title, fontSize, barLaWidth, xList, yList, yLineHeight, pieFontSize, barDistance) {
      return {
        title: {
          text: title,
          textStyle: {
            color: "#162747",
            fontFamily: "微软雅黑",
            fontWeight: "normal",
            fontSize: fontSize,
          },
        },
        grid: {
          left: '9.5%',
          top: '16%',
          bottom: '19%',
        },
        tooltip: {
          formatter: function (params) {
            if (params) {
              return params['name'] + ',' + params['value']
            } else {
              return params['name'] + ',' + params['value']
            }
          }
        },
        xAxis: {
          type: "value",
          min: 0,
          splitNumber: 10,
          nameTextStyle: {
            lineHeight: yLineHeight
          },
          axisLabel: {
            interval: 0,
            margin: 8,
            textStyle: {
              color: "#92A2BC",
              fontSize: fontSize,
            },
          },
          axisLine: {
            show: true,
            lineStyle: {
              width: 1,
              color: "#92A2BC",
            },
          },
          axisTick: {
            show: false,
          },
        },
        yAxis: {
          type: "category",
          axisLine: {
            lineStyle: {
              width: 1,
              color: "#92A2BC", //坐标线的颜色
            },
          },
          axisTick: {
            show: true,
            length: 4,
            alignWithLabel: true,
          },
          data: yList, //this.blackYList
          triggerEvent: true,
          axisLabel: {
            fontSize: pieFontSize,
            formatter: function (value) {
              var res = value;
              if (res.length > 3) {//根据自身需求设置显示字数
                res = res.substring(0, 3) + "..";
              }
              return res;
            },
          },
        },
        series: [
          {
            //name:this.redYList,
            type: "bar",
            barGap: 0,
            emphasis: {
              focus: 'series'
            },
            center: ["50%", "50%"],
            radius: [0, "70%"],
            barWidth: barLaWidth,
            itemStyle: {
              normal: {
                color: "#339C9B",
                barBorderRadius: [0, 100, 100, 0],
              },
            },
            label: {
              show: true,
              //rotate: 0,
              align: 'top',
              verticalAlign: 'center',
              position: 'insideBottomLeft',
              distance: barDistance,
              textStyle: {
                color: "#162747",
                fontFamily: "微软雅黑",
                fontWeight: "normal",
                fontSize: pieFontSize,
              },
              formatter: function (params) {
                return params['name']
              }
            },
            data: xList,
          },
        ],
      };
    },
    //交易数量 + 交易金额
    tranOptions(title, fontSize, pieFontSize, barSmWidth, marginHeight, list, yName, forName) {
      return {
        title: {
          text: title,
          textStyle: {
            color: "#162747",
            fontFamily: "微软雅黑",
            fontWeight: "normal",
            fontSize: fontSize,
          },
        },
        xAxis: {
          type: "category",
          axisLabel: {
            interval: 0,
            rotate: 60,
            margin: marginHeight,
            textStyle: {
              color: "#92A2BC",
              fontSize: pieFontSize,
            },
            formatter: function (value) {
              var res = value;
              if (res.length > 4) {//根据自身需求设置显示字数
                res = res.substring(0, 4) + "..";
              }
              return res;
            },
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: "#92A2BC",
            },
          },
          axisTick: {
            show: false,
          },
        },
        yAxis: {
          type: "value",
          axisLine: {
            show: true,
            lineStyle: {
              color: "#92A2BC",
            },
          },
          axisTick: {
            show: true,
            length: 3,
          },
          axisLabel: {
            textStyle: {
              fontSize: pieFontSize,
            },
            formatter: function (value) {
              var res = String(value);
              if (res.length > 4) {//根据自身需求设置显示字数
                res = res.substring(0, 4) + "..";
              }
              return res;
            },
          },
          name: yName,
        },
        grid: {
          left: "9%",
          right: "2%",
          top: "24%",
          bottom: "21%",
        },
        tooltip: {
          formatter: function (params) {
            if (params.value > 1) {
              return params["value"] + forName;
            } else {
              return params["value"] + forName;
            }
          },
        },
        series: [
          {
            type: "bar",
            center: ["50%", "50%"],
            radius: [0, "70%"],
            barWidth: barSmWidth,
            itemStyle: {
              normal: {
                color: "#339C9B",
                barBorderRadius: [100, 100, 0, 0],
              },
            },
            data: list,
          },
        ],
      };
    },

    //供应商 + 客户交易金额占比
    moneyOption(fontSize, gapLegendWidth, pieLegendWidth, list, pieFontSize, eLength) {
      return {
        tooltip: {
          formatter: function (params) {
            if (params.value > 1) {
              return params["name"] + "：" + params["percent"] + "%";
            } else {
              return params["name"] + "：" + params["percent"] + "%";
            }
          },
        },
        title: {
          text: "交易金额占比",
          textStyle: {
            color: "#162747",
            fontFamily: "微软雅黑",
            fontWeight: "normal",
            fontSize: fontSize,
          },
        },
        grid: {
          left: "2%",
        },
        legend: {
          orient: "vertical",
          right: "0%",
          align: "right",
          itemGap: gapLegendWidth,
          itemWidth: pieLegendWidth,
          itemHeight: pieLegendWidth,
        },
        series: [
          {
            z: "1",
            type: "pie",
            clockwise: false,
            startAngle: 72,
            center: ["38%", "50%"],
            radius: [0, "60%"],
            label: {
              show: true,
              fontSize: pieFontSize,
              borderWidth: 0,
              color: "inherit",
              formatter: function (params) {
                return (
                  params["name"] +
                  "：" +
                  parseFloat(params.percent).toFixed(1) +
                  "%"
                );
              },
            },
            labelLine: {
              show: true,
              length: 10,
              length2: eLength,
            },
            itemStyle: {
              show: false,
              color: function (params) {
                const colorList = [
                  "#0AC8C6",
                  "#339C9B",
                  "#267675",
                  "#004F4E",
                  "#316BCD",
                  "#4A8DFF",
                  "#76B3FF",
                  "#E86C19",
                  "#FF9B15",
                  "#FAC858",
                ];
                return colorList[params.dataIndex];
              },
            },
            data: list,
          },
        ],
      };
    },

    //供应商 + 客户交易地区统计
    areaOption(fontSize, barSmWidth, list, pieFontSize) {
      return {
        title: {
          text: "交易地区统计",
          textStyle: {
            color: "#162747",
            fontFamily: "微软雅黑",
            fontWeight: "normal",
            fontSize: fontSize,
          },
        },
        xAxis: {
          type: "category",
          axisLabel: {
            interval: 0,
            margin: 8,
            textStyle: {
              color: "#92A2BC",
              fontSize: 12,
            },
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: "#92A2BC",
            },
          },
          axisTick: {
            show: false,
          },
        },
        yAxis: {
          type: "value",
          axisLine: {
            show: true,
            lineStyle: {
              color: "#92A2BC",
            },
          },
          axisTick: {
            show: true,
            length: 3,
          },
          name: "单数",
          axisLabel: {
            fontSize: pieFontSize,
            fontFamily: 'Microsoft YaHei'
          },
        },
        grid: {
          left: "7%",
          right: "2%",
          top: "23%",
          bottom: "19%",
        },
        tooltip: {
          formatter: function (params) {
            if (params.value > 1) {
              return params.data[0] + "," + params.data[1] + "个";
            } else {
              return params.data[0] + "," + params.data[1] + "个";
            }
          },
        },
        series: [
          {
            type: "bar",
            center: ["50%", "50%"],
            radius: [0, "70%"],
            barWidth: barSmWidth,
            itemStyle: {
              normal: {
                color: "#339C9B",
                barBorderRadius: [100, 100, 0, 0],
              },
            },
            data: list,
          },
        ],
      };
    },

    //echart字体自适应改变
    resizeFn() {
      if (document.body.clientWidth > 1664) {
        this.fontSize = 14
        this.gapLegendWidth = 14
        this.pieLegendWidth = 15,
          this.barSmWidth = 12
        this.barLaWidth = 20
        this.yLineHeight = 18
        this.barDistance = 9
        this.marginHeight = 6
        this.horGapLegendHeight = 20
        this.eLength = 30
        this.yLineHeight = 18
        this.yLineHeightT = 18
        this.marginHeight = 8

      } else {
        this.fontSize = 12
        this.gapLegendWidth = 7
        this.pieLegendWidth = 12
        this.barSmWidth = 8
        this.barLaWidth = 13
        this.yLineHeight = -11
        this.barDistance = 6
        this.marginHeight = 5
        this.horGapLegendHeight = 13
        this.eLength = 10
        this.yLineHeight = -11
        this.yLineHeightT = 0
        this.marginHeight = 3
      }
      // this.$nextTick(() => {
      //   // //我的供应商
      //   // //供应商红榜
      //   this.redEcharts(this.fontSize,this.barLaWidth,this.yLineHeight,this.pieFontSize,this.barDistance);
      //   //供应商黑榜
      //   this.blackEcharts(this.fontSize,this.barLaWidth,this.yLineHeight,this.pieFontSize,this.barDistance);
      //   //交易数量
      //   this.tranNumEcharts(this.fontSize,this.pieFontSize,this.barSmWidth,this.marginHeight);
      //   //交易金额
      //   this.accountEcharts(this.fontSize,this.pieFontSize,this.barSmWidth,this.marginHeight);
      //   //交易金额占比
      //   // this.propEcharts(this.fontSize,this.gapLegendWidth,this.pieLegendWidth,this.pieFontSize);
      //   //交易地区统计
      //   this.areaEcharts(this.fontSize,this.barSmWidth,this.pieFontSize);
      //   //我的客户
      //   //客户红榜
      //   this.cusRedEcharts(this.fontSize,this.barLaWidth,this.yLineHeight,this.pieFontSize,this.barDistance);
      //   //客户黑榜
      //   this.cusBlackEcharts(this.fontSize,this.barLaWidth,this.yLineHeight,this.pieFontSize,this.barDistance);
      //   //交易数量
      //   this.cusTranNumEcharts(this.fontSize,this.pieFontSize,this.barSmWidth,this.marginHeight);
      //   //交易金额
      //   this.cusAccountEcharts(this.fontSize,this.pieFontSize,this.barSmWidth,this.marginHeight);
      //   //交易金额占比
      //   // this.cusPropEcharts(this.fontSize,this.gapLegendWidth,this.pieLegendWidth,this.pieFontSize);
      //   //交易地区统计
      //   this.cusAreaEcharts(this.fontSize,this.barSmWidth,this.pieFontSize);
      //   //我的服务
      //   this.serviceEcharts(this.fontSize,this.horGapLegendHeight,this.pieLegendWidth);
      // });
    },
  },
};
</script>
<style scoped>
  .nodata {
    width: 220px;
    height: 20px;
    margin: 0 auto;
    line-height: 20px;
    font-size: 16px;
    font-family: '微软雅黑';
    color: #92a2bc;
  }
  .nodataTop {
    margin-top: 150px;
  }
  .text_overflow {
    width: 85px;
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: right;
    cursor: pointer;
  }
  .table_common_work_tit {
    line-height: 22px;
    font-size: 18px;
    margin-bottom: 20px;
  }
  .workbench_link_div {
    width: 100%;
    padding-top: 10px;
    padding-bottom: 40px;
  }

  .workbench_link_div li {
    width: 220px;
    /* height: 328px; */
    margin: 0 35px;
    border-radius: 14px;
    background-color: #fff;
    text-align: center;
    box-shadow: 0px 10px 14px 0px rgba(12, 79, 71, 0.16);
  }
  .workbench_link_div li:nth-child(3) .workbench_bottom {
    cursor: auto;
  }

  .workbench_tit {
    line-height: 60px;
    font-size: 16px;
    color: #339c9b;
  }

  .workbench_icon {
    width: 130px;
    height: 140px;
    padding-bottom: 10px;
    margin: 0 auto;
    border-radius: 50%;
  }
  .icon-xiayiye-xianxing3-0 {
    color: #fff;
    transform: rotate(90deg);
    padding-left: 12px;
  }
  .workbench_lunbo_con {
    width: 1380px;
    overflow: hidden;
  }
  .workbench_link_div {
    width: auto;
    transform: translate3d(0px, 0px, 0px);
  }

  .workbench_link_div li {
    width: 220px;
    /* height: 328px; */
    margin: 0 28px;
    border-radius: 14px;
    background-color: #fff;
    text-align: center;
    box-shadow: 0px 10px 14px 0px rgba(12, 79, 71, 0.16);
    flex-shrink: 0;
  }

  .workbench_tit {
    line-height: 60px;
    font-size: 16px;
    color: #339c9b;
  }

  .workbench_icon {
    width: 130px;
    height: 140px;
    padding-bottom: 10px;
    margin: 0 auto;
    border-radius: 50%;
  }

  .workbench_tol {
    line-height: 38px;
    font-size: 14px;
    color: #92a2bc;
  }

  .workbench_num {
    height: 30px;
    line-height: 30px;
    margin-bottom: 30px;
    font-family: 'Microsoft YaHei Bold';
    font-size: 30px;
  }

  .workbench_bottom {
    width: 100%;
    height: 30px;
    background-color: #339c9b;
    border-bottom-left-radius: 14px;
    border-bottom-right-radius: 14px;
  }

  .workbench_bottom .icon-xiayiye-xianxing3-0 {
    color: #fff;
    transform: rotate(90deg);
    padding-left: 12px !important;
    font-size: 18px;
  }
  .workbench_tol {
    line-height: 38px;
    font-size: 14px;
    color: #92a2bc;
  }

  .workbench_num {
    height: 30px;
    line-height: 30px;
    margin-bottom: 30px;
    font-family: 'Microsoft YaHei Bold';
    font-size: 30px;
  }
  .workbench_bottom {
    width: 100%;
    height: 30px;
    background-color: #339c9b;
    border-bottom-left-radius: 14px;
    border-bottom-right-radius: 14px;
    cursor: pointer;
    overflow: hidden;
  }
  .deg180 {
    transform: rotate(180deg);
  }
  .workbench_lunbo {
    width: 100%;
    padding-top: 10px;
    padding-bottom: 40px;
  }
  .workbench_lunbo_prev {
    width: 50px;
    height: 50px;
    margin-right: 10px;
    border-radius: 50%;
  }
  .workbench_lunbo_next {
    width: 50px;
    height: 50px;
    margin-left: 10px;
    border-radius: 50%;
  }
  .blue {
    box-shadow: 0px 8px 14px 0px rgba(47, 160, 159, 0.23);
  }
  .white {
    box-shadow: 0px 8px 14px 0px rgba(96, 135, 135, 0.23);
  }
  .table_common_work_echart {
    width: 700px;
    height: 340px;
    padding: 20px;
    margin-bottom: 22px;
    border-radius: 10px;
    background-color: #edf2f5;
  }
  .table_common_work_echart_Bar {
    width: 400px;
    height: 236px;
  }
  .table_common_work_echart_details {
    width: 240px;
    height: 236px;
    padding: 10px 20px 0 20px;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0px 0px 5px 0px rgb(9 44 55 / 28%) inset;
  }
  .table_common_work_echart_details li {
    line-height: 40px;
    font-size: 14px;
  }
  .table_common_work_echart_details .tit {
    color: #92a2bc;
  }
  .table_common_work_echart_details.service {
    height: 236px;
    padding: 20px;
  }
  .table_common_work_echart_details.service .tit {
    color: #92a2bc;
  }
  .workbench_table_title_total_item {
    margin-bottom: 40px;
  }

  .workbench_table_title_more .icon-xiayiye-xianxing3-0 {
    padding-left: 6px;
    color: #339c9b;
  }
  .workbench_table_title_more .icon-xiayiye-xianxing3-0 {
    transform: rotate(0deg);
  }
  .backtop_div .icon-huidaodingbu {
    font-size: 36px;
    color: #339c9b;
  }
  .table_common_work_echart1_allwidth {
    width: 100%;
    height: 100%;
  }
  .table_common_work_echart11 {
    width: 100% !important;
    height: 276px;
  }
  .no_mar {
    margin: 0;
  }
  .echart_sup {
    height: 320px;
    width: 700px;
  }
  .no_echart_title {
    margin-top: -320px;
    font-size: 14px;
  }
  .no_echart_tip {
    margin-top: 130px;
    margin-left: 240px;
    font-size: 16px;
    color: #92a2bc;
  }
  .workbench_table_title_total_tit {
    width: 225px;
  }
  .workbench_table_title_total_tit1 {
    width: 340px;
  }
  .table_common_work_echart1 {
    width: 400px;
    height: 100%;
  }
  @media screen and (max-width: 1664px) {
    .nodata {
      width: 150px;
      height: 12px;
      margin: 0 auto;
      line-height: 12px;
      font-size: 12px;
      font-family: '微软雅黑';
      color: #92a2bc;
    }
    .nodataTop {
      margin-top: 100px;
    }
    .text_overflow {
      width: 74px;
    }
    .workbench_link_div li {
      width: 147px;
      /* height: 328px; */
      margin: 0 19px;
      border-radius: 9px;
      background-color: #fff;
      text-align: center;
      box-shadow: 0px 7px 9px 0px rgb(12 79 71 / 16%);
      flex-shrink: 0;
    }
    .workbench_lunbo_prev {
      width: 33px;
      height: 33px;
      margin-right: 6px;
      border-radius: 50%;
    }
    .workbench_lunbo_next {
      width: 33px;
      height: 33px;
      margin-left: 6px;
      border-radius: 50%;
    }
    .backtop_div {
      width: 27px;
      height: 27px;
      box-shadow: 0px 4px 8px 0px rgba(50, 150, 149, 0.19);
    }
    .backtop_div .icon-huidaodingbu {
      font-size: 24px;
    }

    .workbench_tit {
      line-height: 40px;
      font-size: 12px;
    }
    .workbench_icon {
      width: 87px;
      height: 93px;
      padding-bottom: 7px;
    }
    .workbench_tol {
      line-height: 25px;
      font-size: 12px;
    }
    .workbench_num {
      height: 20px;
      line-height: 20px;
      margin-bottom: 20px;
      font-size: 20px;
    }
    .workbench_bottom {
      height: 20px;
      border-bottom-left-radius: 9px;
      border-bottom-right-radius: 9px;
    }
    .workbench_bottom .icon-xiayiye-xianxing3-0 {
      padding-left: 8px;
      font-size: 12px;
    }
    .table_common_work_tit {
      line-height: 15px;
      font-size: 12px;
      margin-bottom: 14px;
    }
    .workbench_table_title_more {
      font-size: 11px;
      margin-top: 2px;
      line-height: 14px;
    }
    .workbench_table_title_more .icon-xiayiye-xianxing3-0 {
      padding-left: 4px;
    }
    .table_common_work_echart {
      width: 467px;
      height: 224px;
      padding: 13px;
      margin-bottom: 15px;
      border-radius: 7px;
    }
    .echart_sup {
      width: 462px;
      height: 211px;
    }
    .table_common_work_echart_details {
      width: 180px;
      height: 157px;
      padding: 7px 14px 0 20px;
      border-radius: 7px;
      background-color: #fff;
      box-shadow: 0px 0px 4px 0px rgb(9 44 55 / 28%) inset;
    }
    .table_common_work_echart_details.service {
      height: 156px;
    }
    .table_common_work_echart_details li {
      line-height: 27px;
      font-size: 12px;
    }
    .table_common_work_echart_Bar {
      width: 320px;
      height: 156px;
    }
    .table_common_work_echart11 {
      height: 184px;
    }
    .no_mar {
      margin: 0;
    }
    .no_echart_title {
      margin-top: -210px;
      font-size: 12px;
    }
    .no_echart_tip {
      margin-top: 85px;
      font-size: 12px;
      margin-left: 158px;
    }

    .workbench_lunbo_con {
      width: 920px;
      overflow: hidden;
    }
    .workbench_link_div {
      width: auto;
    }

    .workbench_link_div li {
      width: 147px;
      border-radius: 9px;
      box-shadow: 0px 7px 9px 0px rgba(12, 79, 71, 0.16);
    }

    .workbench_tit {
      line-height: 40px;
      font-size: 12px;
    }

    .workbench_icon {
      width: 87px;
      height: 93px;
      padding-bottom: 7px;
      margin: 0 auto;
      border-radius: 50%;
    }

    .workbench_tol {
      line-height: 25px;
      font-size: 12px;
    }

    .workbench_num {
      height: 20px;
      line-height: 20px;
      margin-bottom: 20px;
      font-size: 20px;
    }

    .workbench_bottom {
      width: 100%;
      height: 20px;
      border-bottom-left-radius: 9px;
      border-bottom-right-radius: 9px;
    }

    .workbench_bottom .icon-xiayiye-xianxing3-0 {
      transform: rotate(90deg);
      padding-left: 8px !important;
      font-size: 12px;
    }
    .blue {
      box-shadow: 0px 5px 9px 0px rgba(47, 160, 159, 0.23);
    }
    .white {
      box-shadow: 0px 5px 9px 0px rgba(96, 135, 135, 0.23);
    }
    .workbench_table_title_total_tit {
      width: 150px;
    }
    .workbench_table_title_total_tit1 {
      width: 227px;
    }
    .table_common_work_echart1 {
      width: 250px;
    }
  }
</style>